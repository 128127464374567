import React, { useRef, useState, json } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import styled from "styled-components";

const welcomeText = "Welcome.";
const largeScreenIntroText = ["Creating an account is 100% free. Always and forever.", <br />, <br />, "Just sign up, register books you read, share them with others and", <br />, "then sit back and watch them circle the globe spreading joy."];
const smallScreenIntroText = ["Creating an account is 100% free.", <br />, "Always and forever.", <br />, <br />, "Just sign up, register books you read,", <br />, "share them with others and then", <br />, "sit back and watch them circle the", <br />, "globe spreading joy."];

const firstNamePromptText = "First name *";
const lastNamePromptText = "Last name";
const locationPromptText = "Location (city, state, locale) *";
const emailPromptText = "Email address *";

const nameErrorText = "Required. Use only letters and dashes."
const locationErrorText = "Required. Must be a valid location (city, state, locale)."
const emailErrorText = "Required. Must be a valid email address."
const submitErrorText = "Please correct the indicated form errors and submit again."

const TextInput = styled.input`
  display: block;
  margin: 12px 0px;
  font-size: 20px; 
  padding: 8px 10px;
  border: 1px solid black;
  background-color: #EEEEEE;
  color: #000000;
`;

function SignUp() {
    const [firstName, setFirstName] = useState('')
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastName, setLastName] = useState('')
    const [lastNameError, setLastNameError] = useState(false);
    const [location, setLocation] = useState('')
    const [locationError, setLocationError] = useState(false);
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false);

    const inputRef = useRef([]);

    function Submit(event) {
        event.preventDefault();

        console.log(JSON.stringify(location, null, 2));

        var error = false;

        if (firstNameError || firstName.length === 0) {
            setFirstNameError(true)
            error = true;
        }

        if (lastNameError) {
            setLastNameError(true)
            error = true;
        }

        if (locationError || location.length === 0) {
            setLocationError(true)
            error = true;
        }

        if (emailError || email.length === 0) {
            setEmailError(true)
            error = true;
        }

        if (error) {
            alert(submitErrorText)
            return
        }

        const data = new FormData(event.target);
        alert(data.get("firstName") + " " + data.get("lastName") + "\n" + data.get("location") + "\n" + data.get("email"));
    };

    const handleFirstNameBlur = (event) => {
        setFirstNameError(false);
        if (event.target.validity.patternMismatch || event.target.value.length === 0) {
            inputRef.current.firstName.focus();
            inputRef.current.firstName2.focus();
            setFirstNameError(true);
        }
    }

    const handleLastNameBlur = (event) => {
        setLastNameError(false);
        if (event.target.validity.patternMismatch) {
            inputRef.current.lastName.focus();
            inputRef.current.lastName2.focus();
            setLastNameError(true);
        }
    }

    const handleEmailBlur = (event) => {
        setEmailError(false);
        if (event.target.validity.patternMismatch || event.target.value.length === 0) {
            inputRef.current.email.focus();
            inputRef.current.email2.focus();
            setEmailError(true);
        }
    }

    function textInputStyle(width, error) {
        if (error) {
            return {
                width: width,
                border: "1px solid red",
                color: "#FF0000A0"
            }
        } else {
            return {
                width: width,
                border: "1px solid black",
                color: "#000000"
            }
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", flexWrap: "wrap" }}>
            <p className="app-hero-text">{welcomeText}</p>
            <p className="app-hero-subtext large-screen">{largeScreenIntroText}</p>
            <p className="app-hero-subtext small-screen">{smallScreenIntroText}</p>
            <form onSubmit={Submit}>
                <div align="center" style={{ marginTop: "30px" }}>
                    <div className="large-screen">
                        <TextInput style={textInputStyle("100%", firstNameError)} placeholder={firstNamePromptText} id="firstName" name="firstName" ref={ref => inputRef.current.firstName = ref} value={firstName} type="text" inputMode='text' pattern="[a-zA-Z]+[a-zA-Z \-]*" onBlur={handleFirstNameBlur} onChange={(e) => setFirstName(e.target.value)} />
                        {firstNameError && (
                            <p role="alert" style={{ color: "#FF0000" }}>
                                {nameErrorText}
                            </p>
                        )}
                        <TextInput style={textInputStyle("100%", lastNameError)} placeholder={lastNamePromptText} id="lastName" name="lastName" ref={ref => inputRef.current.lastName = ref} value={lastName} type="text" inputMode='text' pattern="[a-zA-Z]*[a-zA-Z \-]*" onBlur={handleLastNameBlur} onChange={(e) => setLastName(e.target.value)} />
                        {lastNameError && (
                            <p role="alert" style={{ color: "#FF0000" }}>
                                {nameErrorText}
                            </p>
                        )}
                        <GooglePlacesAutocomplete style={textInputStyle("100%", locationError)}
                            selectProps={{
                                location,
                                onChange: setLocation,
                                placeholder: locationPromptText,
                                styles: {
                                    container: (provided, state) => ({
                                        ...provided,
                                        padding: "0px",
                                    }),
                                    control: (provided, state) => ({
                                        ...provided,
                                        fontSize: "20px",
                                        padding: "2px 0px",
                                        color: '#000000',
                                        backgroundColor: "#EEEEEE",
                                        borderRadius: "0",
                                        textAlign: "left",
                                        hover: "1px solid black",
                                        border: "1px solid black",
                                        "&:hover": {
                                            borderColor:"#000000"
                                        },
                                    }),
                                    dropdownIndicator: (provided, state) => ({
                                        ...provided,
                                        color: "#888888",
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        padding: "0px",
                                        color: '#000000',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: '#000000',
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        padding: "0px",
                                        color: '#000000',
                                    }),
                                },
                            }}
                            apiKey="AIzaSyBhCIJZOAIzAIKTpuikahxeaSEmKas7kQI"
                            autocompletionRequest={{
                                types: ["(cities)"]
                            }}
                        />
                        {locationError && (
                            <p role="alert" style={{ color: "#FF0000" }}>
                                {locationErrorText}
                            </p>
                        )}
                        <TextInput style={textInputStyle("100%", emailError)} placeholder={emailPromptText} name="email" id="email" ref={ref => inputRef.current.email = ref} value={email} type="text" inputMode='email' pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,63}" onBlur={handleEmailBlur} onChange={(e) => setEmail(e.target.value)} />
                        {emailError && (
                            <p role="alert" style={{ color: "#FF0000", marginBottom: "20px" }}>
                                {emailErrorText}
                            </p>
                        )}
                    </div>
                    <div className="small-screen">
                        <TextInput style={textInputStyle("90%", firstNameError)} placeholder={firstNamePromptText} id="firstName" name="firstName" ref={ref => inputRef.current.firstName2 = ref} value={firstName} type="text" inputMode='text' pattern="[a-zA-Z]+[a-zA-Z \-]*" onBlur={handleFirstNameBlur} onChange={(e) => setFirstName(e.target.value)} />
                        {firstNameError && (
                            <p role="alert" style={{ color: "#FF0000" }}>
                                {nameErrorText}
                            </p>
                        )}
                        <TextInput style={textInputStyle("90%", lastNameError)} placeholder={lastNamePromptText} id="lastName" name="lastName" ref={ref => inputRef.current.lastName2 = ref} value={lastName} type="text" inputMode='text' pattern="[a-zA-Z]*[a-zA-Z \-]*" onBlur={handleLastNameBlur} onChange={(e) => setLastName(e.target.value)} />
                        {lastNameError && (
                            <p role="alert" style={{ color: "#FF0000" }}>
                                {nameErrorText}
                            </p>
                        )}
                        <GooglePlacesAutocomplete style={textInputStyle("90%", locationError)}
                            selectProps={{
                                location,
                                onChange: setLocation,
                                placeholder: locationPromptText,
                                styles: {
                                    container: (provided, state) => ({
                                        ...provided,
                                        padding: "0px",
                                        width: "90%"
                                    }),
                                    control: (provided, state) => ({
                                        ...provided,
                                        fontSize: "20px",
                                        padding: "2px 0px",
                                        color: '#000000',
                                        backgroundColor: "#EEEEEE",
                                        borderRadius: "0",
                                        textAlign: "left",
                                        hover: "1px solid black",
                                        border: "1px solid black",
                                        "&:hover": {
                                            borderColor:"#000000"
                                        },
                                    }),
                                    dropdownIndicator: (provided, state) => ({
                                        ...provided,
                                        color: "#888888",
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        padding: "0px",
                                        color: '#000000',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: '#000000',
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        padding: "0px",
                                        color: '#000000',
                                    }),
                                },
                            }}
                            apiKey="AIzaSyBhCIJZOAIzAIKTpuikahxeaSEmKas7kQI"
                            autocompletionRequest={{
                                types: ["(cities)"]
                            }}
                        />
                        {locationError && (
                            <p role="alert" style={{ color: "#FF0000" }}>
                                {locationErrorText}
                            </p>
                        )}
                        <TextInput style={textInputStyle("90%", emailError)} placeholder={emailPromptText} name="email" id="email" ref={ref => inputRef.current.email2 = ref} value={email} type="text" inputMode='email' pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,63}" onBlur={handleEmailBlur} onChange={(e) => setEmail(e.target.value)} />
                        {emailError && (
                            <p role="alert" style={{ color: "#FF0000", marginBottom: "20px" }}>
                                {emailErrorText}
                            </p>
                        )}
                    </div>
                    <p className="app-footnote">* Required field</p>
                    <div align="center">
                        <input style={{ width: "120px", marginBottom: "30px" }} className="app-button" type="submit" value="Sign up" />
                    </div>
                </div>
            </form>
            {/* <p style={{ marginTop: "30px" }} className="app-footnote">Already have an account?&nbsp;&nbsp;<a href="#">Log In</a></p> */}
        </div>
    );
};

export default SignUp;