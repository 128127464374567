import React, { useRef, useState, useEffect } from 'react';
import ReactModal from "react-modal";

import {
    HashRouter as Router,
    Routes,
    Route,
    Link,
    Navigate,
    useLocation
} from "react-router-dom";

import AboutUs from './AboutUs.js'
import SignUp from './SignUp.js'

import logo from './logo.svg';
import x from './x.svg'
import facebook from './facebook.svg'
import instagram from './instagram.svg'
import linkedin from './linkedin.svg'
import youtube from './youtube.svg'
import read from './read.png'
import share from './share.png'
import follow from './follow.jpg'
import hamburger from './hamburger.svg'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import './App.css';
import './Header.css';
import './Footer.css';
import './IntroSwiper.css';
import './IntroList.css';

const companyNameText = "Read. Share. Follow."
const comingSoonText = "Coming Soon...";
const largeScreenHeadlineText = ["Ever wonder what happens to a book that you shared with a", <br />, "friend or donated to a Little Free Library?"];
const smallScreenHeadlineText = ["Ever wonder what happens", <br />, "to a book that you shared with", <br />, "a friend or donated to a Little", <br />, "Free Library?"];
const largeScreenHeroSubtext = ["Read books. Share them with your community.", <br />, "Watch them travel the world and touch lives everywhere."];
const smallScreenHeroSubtext = ["Read books. Share them with your community. Watch them travel the world and touch lives everywhere."];
const learnMoreText = "Learn more";

const introReadSubtitleText = "Login to your account and register your books as you read them. All we need is the ISBN on the back cover to add it to your account.";
const introReadText = "Read a book and register it."
const introShareSubtitleText = ["Give the book to a friend, donate it to a local bookstore or charity, or drop it off at a ", <a href="https://littlefreelibrary.org/" target="_blank">Little Free Library</a>, " for others to read and enjoy."];
const introShareText = "Share the book with others."
const introFollowSubtitleText = "As others register the book you've shared, you can track it's whereabouts, see who's read it and see what else they're reading.";
const introFollowText = "Follow the book on its journey."

document.body.style = 'background: #FFFFFF;';

const company = {
    name: "Read. Share. Follow."
}

const ScrollToTop = () => {
    // Extracts pathname property(key) from an object
    const { pathname } = useLocation();

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
}

function Title() {
    return (
        <>
            <div className="app-header-company-name">{companyNameText}</div>
        </>
    );
}

function Header(isLoggedIn) {
    const location = useLocation();
    const hideSignup = (location.pathname === '/signup');

    return (
        <div className="app-header">
            <div className="app-header-section">
                <a href="/"><img src={logo} className="app-header-item app-header-logo" alt="Read. Share. Follow." /></a>
                <Title />
            </div>
            <div className="app-header-section">
                {!isLoggedIn && <div className="app-header-item app-header-button">
                    Login
                </div>}
                {!hideSignup && <Link to="/signup" style={{ textDecoration: 'none' }}>
                    <div className="app-header-item app-header-button is-app-header-item-selected large-screen">
                        Sign up
                    </div>
                </Link>}
                <div className="app-header-item app-header-button small-screen" style={{ width: "40px" }}>
                    <a href="#"><img className="app-header-menu" src={hamburger} alt="Menu"></img></a>
                </div>
            </div>
        </div>
    );
}

function Carousel() {
    return (
        <div className="large-screen">
            <Swiper
                style={{
                    marginTop: "30px",
                    width: "80%"
                }}
                slidesPerView={1}
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 10000,
                    disableOnInteraction: true,
                    pauseOnMouseEnter: true
                }}
                pagination={{
                    el: '.app-intro-swiper-custom-pagination',
                    bulletClass: 'swiper-pagination-bullet app-intro-swiper-pagination-bullet',
                    bulletActiveClass: 'swiper-pagination-bullet-active app-intro-swiper-pagination-bullet-active',
                    clickable: true
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="app-intro-swiper"
            >
                <SwiperSlide className="app-intro-swiper-slide">
                    <div className="app-intro-swiper-slide-content">
                        <div className="app-intro-swiper-slide-photo">
                            <img src={read} className="app-intro-swiper-slide-image" style={{ objectPosition: "right" }} />
                        </div>
                        <div className="app-intro-swiper-slide-text-container">
                            <div className="app-intro-swiper-slide-title">
                                <span style={{ color: "#229EFE" }}><b>READ...</b></span>
                            </div>
                            <div className="app-intro-swiper-slide-subtitle">
                                {introReadSubtitleText}
                            </div>
                            <div className="app-intro-swiper-slide-text">
                                {introReadText}
                            </div>
                            <div className="app-intro-swiper-slide-link">
                                <a href="#">{learnMoreText}</a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="app-intro-swiper-slide">
                    <div className="app-intro-swiper-slide-content">
                        <div className="app-intro-swiper-slide-photo">
                            <img src={share} className="app-intro-swiper-slide-image" style={{ objectPosition: "center" }} />
                        </div>
                        <div className="app-intro-swiper-slide-text-container">
                            <div className="app-intro-swiper-slide-title">
                                <span style={{ color: "#229EFE" }}><b>SHARE...</b></span>
                            </div>
                            <div className="app-intro-swiper-slide-subtitle">
                                {introShareSubtitleText}
                            </div>
                            <div className="app-intro-swiper-slide-text">
                                {introShareText}
                            </div>
                            <div className="app-intro-swiper-slide-link">
                                <a href="#">{learnMoreText}</a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="app-intro-swiper-slide">
                    <div className="app-intro-swiper-slide-content">
                        <div className="app-intro-swiper-slide-photo">
                            <img src={follow} className="app-intro-swiper-slide-image" style={{ objectPosition: "center" }} />
                        </div>
                        <div className="app-intro-swiper-slide-text-container">
                            <div className="app-intro-swiper-slide-title">
                                <span style={{ color: "#229EFE" }}><b>FOLLOW...</b></span>
                            </div>
                            <div className="app-intro-swiper-slide-subtitle">
                                {introFollowText}
                            </div>
                            <div className="app-intro-swiper-slide-text">
                                {introFollowSubtitleText}
                            </div>
                            <div className="app-intro-swiper-slide-link">
                                <a href="#">{learnMoreText}</a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className="app-intro-swiper-custom-pagination" />
        </div>
    )
}

function List() {
    return (
        <div className="small-screen">
            <div className="intro-list">
                <div className="intro-list-section">
                    <div className="intro-list-section-photo">
                        <img src={read} className="intro-list-section-image" style={{ objectPosition: "right" }} />
                    </div>
                    <div className="intro-list-section-text-container">
                        <div className="intro-list-section-title">
                            <span style={{ color: "#229EFE" }}><b>READ...</b></span>
                        </div>
                    </div>
                    <div className="intro-list-section-subtitle">
                        {introReadText}
                    </div>
                    <div className="intro-list-section-text">
                        {introReadSubtitleText}
                    </div>
                    <div className="intro-list-link">
                        <a href="#">{learnMoreText}</a>
                    </div>
                </div>
                <div className="intro-list-section">
                    <div className="intro-list-section-photo">
                        <img src={share} className="intro-list-section-image" style={{ objectPosition: "right" }} />
                    </div>
                    <div className="intro-list-section-text-container">
                        <div className="intro-list-section-title">
                            <span style={{ color: "#229EFE" }}><b>SHARE...</b></span>
                        </div>
                    </div>
                    <div className="intro-list-section-subtitle">
                        {introShareText}
                    </div>
                    <div className="intro-list-section-text">
                        {introShareSubtitleText}
                    </div>
                    <div className="intro-list-link">
                        <a href="#">{learnMoreText}</a>
                    </div>
                </div>
                <div className="intro-list-section">
                    <div className="intro-list-section-photo">
                        <img src={follow} className="intro-list-section-image" style={{ objectPosition: "right" }} />
                    </div>
                    <div className="intro-list-section-text-container">
                        <div className="intro-list-section-title">
                            <span style={{ color: "#229EFE" }}><b>FOLLOW...</b></span>
                        </div>
                    </div>
                    <div className="intro-list-section-subtitle">
                        {introFollowText}
                    </div>
                    <div className="intro-list-section-text">
                        {introFollowSubtitleText}
                    </div>
                    <div className="intro-list-link">
                        <a href="#">{learnMoreText}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Footer() {
    return (
        <footer>
            <section className="app-footer-main">
                <div className="app-footer-item">
                    <h2 className="app-footer-title">About</h2>
                    <ul>
                        <li><Link to="/aboutus" className="app-link">Our Story</Link></li>
                        {/* <li><a href="#">Assets</a></li> */}
                        {/* <li><a href="#">Donate</a></li> */}
                    </ul>
                </div>
                <div className="app-footer-item">
                    <h2 className="app-footer-title">Resources</h2>
                    <ul>
                        <li><a href="https://readsharefollow.freshdesk.com/support/solutions" target="_blank" rel="noreferrer">FAQ</a></li>
                        <li><a href="https://readsharefollow.freshdesk.com" target="_blank" rel="noreferrer">Support</a></li>
                    </ul>
                </div>
                <div className="app-footer-item">
                    <h2 className="app-footer-title">Contact</h2>
                    <ul>
                        <li><a href="mailto:contact@readsharefollow.com" target="_blank" rel="noreferrer">Email</a></li>
                        {/* <li><a href="#">Advertise</a></li> */}
                    </ul>
                </div>
            </section>
            <section className="app-footer-social">
                <ul className="app-footer-social-list">
                    <li><a href="https://twitter.com/readsharefollow" target="_blank" rel="noreferrer"><img className="app-footer-social-logo" src={x} alt="Twitter (X)"></img></a></li>
                    <li><a href="https://facebook.com/readsharefollow" target="_blank" rel="noreferrer"><img className="app-footer-social-logo" src={facebook} alt="Facebook"></img></a></li>
                    <li><a href="https://www.instagram.com/read.share.follow" target="_blank" rel="noreferrer"><img className="app-footer-social-logo" src={instagram} alt="Instagram"></img></a></li>
                    <li><a href="https://linkedin.com/company/read-share-follow" target="_blank" rel="noreferrer"><img className="app-footer-social-logo" src={linkedin} alt="LinkedIn"></img></a></li>
                    <li><a href="https://www.youtube.com/@readsharefollow" target="_blank" rel="noreferrer"><img className="app-footer-social-logo" src={youtube} alt="You Tube"></img></a></li>
                </ul>
            </section>
            <section className="app-footer-legal">
                <ul className="app-footer-legal-list">
                    <li><a href="#">Terms &amp; Conditions</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                    <li>&copy; 2024 Ximii, LLC. All Rights Reserved.</li>
                </ul>
            </section>
        </footer>
    );
}

function Home() {
    return (
        <>
            <p className="app-hero-text">{comingSoonText}</p>
            <p className="app-hero-subtext large-screen"><b>{largeScreenHeadlineText}</b></p>
            <p className="app-hero-subtext small-screen"><b>{smallScreenHeadlineText}</b></p>
            <p></p>
            <p className="app-hero-subtext large-screen">{largeScreenHeroSubtext}</p>
            <p className="app-hero-subtext small-screen">{smallScreenHeroSubtext}</p>
            <p className="app-hero-subtext"><Link to="/signup" className="app-link">Sign up</Link> for early access.</p>
            <Carousel />
            <List />
            <div align="center">
                <Link to="/signup" style={{ textDecoration: 'none' }}>
                    <div style={{ marginTop: "30px", marginBottom: "30px", width: "120px" }} className="app-button">
                        Sign up
                    </div>
                </Link>
            </div>
        </>
    )
}

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(true)

    return (
        <>
            <Router>
                <Header isLoggedIn={isLoggedIn} />
                <div className="app-body">
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/aboutus" element={<AboutUs />} />
                    </Routes>
                </div>
                <Footer />
            </Router>
        </>
    )
}

export default App;