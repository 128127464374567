import React, { useRef, useState, json } from 'react';
const welcomeText = "About Us.";
const aboutText = [`We started this company on a whim that we conceived while laying on a beach in Honolulu, Hawaii.
    We spent hours and days reading books, basking in the Hawaiian sun and then donating our books to the Little Free 
    Library at the hotel pool. We came back often to check them and commenting to ourselves when the books were 
    gone minutes or hours later in many cases.`, <p/>, <br/>, `After a conversation with another couple to whom we 
    donated a book to read on their way back home (to Germany), we had an idea - wouldn't it be fun and fulfilling to 
    know where that book went? People travel from all over the world to visit a place like Hawaii. Where did the book 
    end up? Where would it go on its next journey?`, <p/>, <br/>, `That was the genesis of `, <b>Read. Share. Follow.</b>, 
    ` Read a book, register it, pass it along, and follow it on its journey.  We are avid readers and believe that reading 
    can not only expand your own knowledge, understanding, empathy and potential, but can do the same for others when 
    you pass those experiences onto others through sharing.`, <p/>, <br/>, <b>Read. Share. Follow. </b>, `is a small, 
    privately owned company located in Seattle, WA that is 100% owned and operated by us and only us. We collect affiliate
    revenue and accept donations in order to sustain our services.`];

function AboutUs() {
    const [firstName, setFirstName] = useState('')

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", flexWrap: "wrap" }}>
            <p className="app-hero-text">{welcomeText}</p>
            <p className="app-hero-subtext large-screen" style={{width: "75%", marginBottom: "20px"}}>{aboutText}</p>
            <p className="app-hero-subtext small-screen" style={{width: "80%", marginBottom: "20px"}}>{aboutText}</p>
        </div>
    );
};

export default AboutUs;